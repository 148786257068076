<template>
  <div
    class="
      space-y-3
      lg:w-2/12 lg:pt-6
      pl-8
      lg:px-2 lg:space-y-0
      border-r-2 border-yellow-700
      dark:border-yellow-600
    "
  >
    <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
      <router-link to="/product"><LeftMenuItem1 /></router-link>
    </div>
    <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
      <router-link to="/product"><LeftMenuItem2 /></router-link>
    </div>
        <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
              <router-link to="/product"
                ><LeftMenuItem4
              /></router-link>
    </div>
        <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
      <router-link to="/product"><LeftMenuItem5 /></router-link>
    </div>
        <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
      <router-link to="/product"><LeftMenuItem6 /></router-link>
    </div>
        <div class="flex relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-2.5 -left-5 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18 12H6"
        />
      </svg>
      <router-link to="/product"><LeftMenuItem7 /></router-link>
    </div>
  </div>
</template>

<script>
import LeftMenuItem1 from "@/components/share/LeftMenuItems/LeftMenuItem1";
import LeftMenuItem2 from "@/components/share/LeftMenuItems/LeftMenuItem2";
import LeftMenuItem4 from "@/components/share/LeftMenuItems/LeftMenuItem4";
import LeftMenuItem5 from "@/components/share/LeftMenuItems/LeftMenuItem5";
import LeftMenuItem6 from "@/components/share/LeftMenuItems/LeftMenuItem6";
import LeftMenuItem7 from "@/components/share/LeftMenuItems/LeftMenuItem7";
export default {
  name: "LeftMenu",
  components: {
    LeftMenuItem1,
    LeftMenuItem2,
    LeftMenuItem4,
    LeftMenuItem5,
    LeftMenuItem6,
    LeftMenuItem7,
  },
};
</script>

<style></style>
